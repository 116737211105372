@import "../comon.function.scss";

main.post_entries {
  flex-basis: calc( 100% - 375px );
  align-self: flex-start;
  padding: 5px;
  display: flex;
  flex-flow: wrap;
  justify-content: left;
  align-content: center;
  gap: 15px;
  container-type: inline-size;
  @include sp_base() {
    flex-basis: 100%;
    padding: 10px;
  }
  > h2 {
    flex-basis: 100%;
    font-size: 20pt;
    @include sp_base() {
      margin-bottom: 0;
      text-align: center;
    }
  }
  > div.hero_articles {
    flex-basis: 100%;
    display: flex;
    flex-flow: wrap;
    > article.first_card {
      flex-basis: 50%;
      > a.link_wrap {
        display: block;
        width: 100%;
        > div.thumbnail {
          width: 100%;
          > figure {
            display: block;
            position: relative;
            width: 100%;
            aspect-ratio: 16 / 9;
            > img {
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              border-radius: 10px;
            }
            > span.no_image {
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%, -50%);
              font-size: 20pt;
            }
          }
        }
        > div.details {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
  > article.post {
    //flex-basis: calc( 50% - 15px );
    flex-basis: 100%;
    > .link_wrap {
      display: flex;
      flex-flow: wrap;
      > div.thumbnail {
        //width: 30%;
        flex-basis: 30%;
        @include sp_base() {
          flex-basis: 100%;
        }
        figure {
          width: 100%;
          aspect-ratio: 16 / 9;
          position: relative;
          overflow: hidden;
          > img {
            position: absolute;
            top: 50%; left: 50%;
            transform: translate( -50%, -50% );
            width: 100%;
            border-radius: 10px;
          }
          > span.no_image {
            display: block;
            position: absolute;
            top: 50%; left: 50%;
            transform: translate( -50%, -50% );
            font-size: 17pt;
          }
        }
      }
      div.details {
        flex-basis: 70%;
        padding: 10px;
        padding-bottom: 19pt;
        position: relative;
        @include sp_base() {
          flex-basis: 100%;
        }
        > h3 {
          margin: 0;
          margin-bottom: 10px;
        }
        > aside.description {
          display: block;
          width: 100%;
          font-size: 11pt;
          color: var( --foreground-color-secondary );
        }
        > span.datetime {
          position: absolute;
          right: 0; bottom: 0;
          text-align: right;
          font-size: 11pt;
          @include sp_base() {
            padding-right: 15px;
          }
        }
      }
    }
  }
  > article.tips {
    flex-basis: 100%;
    background-color: var( --background-primary-dark-color );
    container-type: inline-size;
    > a.link_wrap {
      display: block;
      width: 100%;
      padding: 7px;
      border: 1px solid transparent;
      transition: all .3s ease;
      @container ( max-width: 500px ) {
        background-color: var( --background-primary-color );
      }
      &:hover {
        border: 1px solid var( --border-color-secondary );
        background-color: var( --background-primary-color );
      }
      > div.meta_details {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        padding-bottom: 10px;
        border-bottom: 1px dotted var( --border-color );
        container-type: inline-size;
        @container ( max-width: 500px ) {
          flex-flow: wrap;
        }
        > div.categories {
          max-width: 70cqw;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          gap: 10px;
          @container ( max-width: 500px ) {
            max-width: 100cqw;
            width: 100cqw;
            justify-content: center;
          }
          > span {
            display: inline-block;
            padding: 5px 15px;
            font-size: 11pt;
            border-radius: 25px;
            background-color: var( --background-secondary-color );
          }
        }
        > div.time {
          flex-basis: auto;
          flex-grow: 1;
          @container ( max-width: 500px ) {
            flex-basis: 100%;
          }
          > span {
            display: block;
            width: 100%;
            text-align: left;
            font-size: 12pt;
            @container ( max-width: 500px ) {
              text-align: center;
            }
          }
        }
      }
      > div.heading {
        width: 100%;
        padding: 10px;
        > h3 {
          display: block;
          width: 100%;
          font-size: 16pt;
          margin: 0;
        }
      }
    }
  }
}