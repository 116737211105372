@import "../app/comon.function.scss";

div.sidebar {
  width: calc( 375px - 15px );
  align-self: stretch;
  padding: 10px;
  @include sp_base() {
    flex-basis: 100%;
  }
  p {
    font-size: 16px;
  }
  aside.advertise_content {
    display: block;
    width: 100%;
    > div.responsive {
      display: block;
      width: 100%;
      text-align: center;
    }
    > div.pc_square {
      display: block;
      width: 100%;
      text-align: center;
      @include sp_base() {
        display: none;
      }
    }
    > div.sp_square {
      display: none;
      width: 100%;
      //text-align: center;
      @include sp_base() {
        display: block;
      }
    }
  }
  aside.profile {
    display: block;
    width: 100%;
    figure.icon {
      display: block;
      width: 60%;
      position: relative;
      aspect-ratio: 1 / 1;
      margin: 10px auto;
      overflow: hidden;
      //border-radius: 100%;
      .image {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate( -50%, -50% );
        width: 180px;
        height: 180px;
        border-radius: 100%;
      }
    }
    span.author_name {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 16pt;
      margin: 10px 0;
    }
    div.author_description {
      padding: 5px;
    }
    div.author_sns {
      margin: 10px 0;
      text-align: center;
      a {
        font-size: 12pt;
        margin: 0 5px;
      }
    }
  }
  aside.x_timelinee {
    display: block;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    > div.wrapper {
      display: block;
      width: 100%;
    }
  }
  div.sticky_content {
    position: sticky;
    top: 50px;
    width: 315px;
    max-height: calc( 100vh - 50px );
  }
}