div.more_button_wrap {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px 10px;
  > button.more {
    display: inline-block;
    padding: 6px 25px;
    border-radius: 15px;
    background-color: var( --background-secondary-color );
    cursor: pointer;
    font-size: 13pt;
  }
}