.toc-list  {
  display: block;
  width: 100%;
  max-height: 50%;
  overflow-y: auto;
  > li.toc-list-item {
    display: block;
    width: 100%;
    padding: 5px;
    padding-left: 15px;
    > a {
      display: block;
      font-size: 12pt;
    }
  }
  > li.toc-list-item.is-active-li {
    font-weight: bold;
    border-left: 3px solid var( --primary-color );
    background-color: var( --tocbot-selected-background );
  }
}